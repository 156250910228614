.sectionButtons {
    position: relative;
    display: flex;
    button {
    min-width: 150px;
    padding: 15px 20px;
    font-weight: 600;
    border-radius: 30px;
    font-size: 17px;
    line-height: 20px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    
    gap: 5px;
    }
}
@media (max-width: 1024px) {
    .sectionButtons {
        align-self: center;
        button {
            margin-top: 20px;
        }
    }
}
.buttonActive {
    background: #165089;
    color: #FFFFFF;
    position: relative;
    z-index: 2;
    justify-content: center;
    svg {
        fill: #FFFFFF;
    }
}

.buttonPassive {
    background: #F3F3F7;
    color: #165089;
    z-index: 1;
    position: relative;
    right: 0;
    width: 160px;
    svg {
        fill: #165089;
    }
}

.buttonPassiveMap {
    justify-content: end;
}

.sectionButtons button:first-child {
    margin-right: -40px;
}