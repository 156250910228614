.link_title {
    font-size: 20px;
    font-weight: 600;
}

.link {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 0 40%;
    padding: 15px 100px 15px 20px;
    border-radius: 15px;
    background-color: #F3F3F7;
    color: #165089;
    font-size: 17px;
    svg {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        fill: #165089
    }
}