[data-reveal="left"]{
    clip-path: inset(0 100% 0 0);
  }
  
[data-reveal="left"].revealed{
    animation: reveal-left 1.2s cubic-bezier(0.17, 0.97, 0.38, 1) forwards 300ms;
  }
  
  @keyframes reveal-left{
    0% {
      clip-path: inset(0 100% 0 0);
    }
    100% {
      clip-path: inset(0 0 0 0);
    }
  }
  
.data_body img{
    transform: scale(1.5);
    transition: 1.2s cubic-bezier(0.17, 0.97, 0.38, 1);
  }
  
.revealed img{
    transform: scale(1.5);
  }