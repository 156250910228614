.card_item {
    display: flex;
    align-items: stretch;
    width: 430px;
    max-height: 150px;
    min-height: 150px;
    border-radius: 10px;
    overflow: hidden;
    img {
        object-fit: cover;
        width: 35%;
    }
}

@media (max-width: 1440px) {
    .card_item  {
        width: 476px;
        img {
            width: 40%;
        }
    }
}
.text {
    width: 65%;
    padding: 10px 20px;
    background-color: #F3F3F7;
    display: flex;
    align-items: center;
    font-size: 18px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    line-height: 1.5;

}
