.high_title {
    background-image: url(../../assets/history-bg.png);
    padding: 150px 0 120px;
    text-align: center;
    font-weight: 700;
    font-size: 64px;
    color: #FFFFFF;
}

@media (max-width: 1024px) {
    .high_title {
        padding: 100px 0 60px;
        font-size: 32px;
    }
  }

.wrapper {
    padding: 0 240px 150px;
}

@media (max-width: 1900px) {
    .wrapper  {
      padding: 0 30px 100px;
    }
}

@media (max-width: 1440px) {
    .wrapper  {
      padding: 0 60px 100px;
    }
}
  
@media (max-width: 1024px) {
    .wrapper  {
      padding: 0 20px 20px;
    }
}

.person_title {
    margin-top: 50px;
    font-weight: 700;
    font-size: 44px;
}

@media (max-width: 1024px) {
    .person_title  {
        margin-top: 25px;
        font-size: 22px;
    }
}

.person_information {
    margin: 25px 0;
    display: flex;
    gap: 30px;
}

@media (max-width: 748px) {
    .person_information   {
        flex-direction: column;
        gap: 10px;
    }
}

.left_block {
    flex: 0 0 30%;
    display: flex;
    flex-direction: column;

    img {
        width: 100%;
        max-width: 400px;
        align-self: center;        
    }
}

.object_on_map {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
}

@media (max-width: 1024px) {
    .object_on_map   {
        margin-bottom: 10px;
        font-size: 18px;
    }
}