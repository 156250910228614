.container {
    position: absolute;
    width: 100%;
    z-index: 20;   
}

.header {    
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;
    padding: 30px 100px;
}

@media (max-width: 1440px) {
    .header {
        width: 100%;
        padding: 15px 60px;
    }
}
@media (max-width: 1024px) {
    .header {
        padding: 30px 20px;
    }
}
.logo {
    cursor: pointer;
}
@media (max-width: 1024px) {
    .logo img {
        width: 54px;
    }
}
.icons_container {
    display: flex;
    align-items: center;
    gap: 40px;
}

@media (max-width: 1024px) {
    .icons_container {
        display: none;
    }
}

.mobile_icons_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .mobile_search {
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #FFFFFF33;
        svg {
            top: 0;
        }
    }
}
@media (min-width: 1025px) {
    .mobile_icons_container{
        display: none;
    }
}
.link_cont {
    cursor: pointer;
    padding: 5px 0;
    height: 30px;
    position: relative;
    overflow: hidden;
    border-bottom: 2px solid transparent;
    color: #FFFFFF;
    font-size: 18px;
    .first_item {
        position: absolute;
        transition:  .5s;
        right: 0;
        transform: translateY(-30px);
    }
    .second_item {
        position: relative;
        transition: .5s;
        right: 0;
        top: 0;
    }

}
.link_cont:hover .first_item {
    transform: translateY(0px);
}
.link_cont:hover .second_item {
   top: 40px;
}

[aria-current="page"] {
    border-bottom: 2px solid #FFFFFF;
}