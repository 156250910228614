.footer_comtainer {
    background-color: #F3F3F7;
    padding: 125px 100px 60px;
    color: #4F4F4F;
    line-height: 27px;
    font-size: 18px;
    a {
        color: #4F4F4F;
        &:hover {
            color: #165089;
            text-decoration: underline;
        }
        &:active {
            color: #000B16;
        }
    }
}

@media (max-width: 1440px) {
    .footer_comtainer  {
        padding: 125px 25px 60px;
    }
}

@media (max-width: 1024px) {
    .footer_comtainer  {
        padding: 60px 25px 30px;
        a {
            color: #165089;
        }
    }
}

.blocks {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 1024px) {
    .blocks  {
        flex-direction: column;
    }
}
.left_block {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media (max-width: 1024px) {
    .left_block  {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.right_block {
    align-self: end;
}

@media (max-width: 1024px) {
    .right_block   {
        margin-top: 20px;
        align-self: center;
    }
}
.end_block {
    margin-top: 50px;
    text-align: center;
}