.news {
    display: flex;
    align-items: stretch;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
  }
  
  .news_text {
    background-color: #F3F3F7;
    padding: 20px;
    border-radius: 0 10px 10px 0;
  }
  
  .title {
    font-size: 22px;
    font-weight: 600;
    color: #000B16;
  }
  
  .text {
    margin-top: 5px;
    color: #4F4F4F;
    font-size: 18px;
  }