.languages_container {
    cursor: pointer;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #000B16;
    position: relative;
    user-select: none;
}

.selected_language {
    display: flex;
    gap: 10px;
    align-items: center;

    svg {
        position: relative;
        top: -1px;
        transition: .3s;
    }
}

@media (max-width: 1024px) {
    .selected_language {
        width: 40px;
        height: 40px;
        justify-content: center;
        border-radius: 50%;
        background-color: #FFFFFF33;
        svg {
            top: 0;
        }
    }
}

.languages {
    border-radius: 8px;
    position: absolute;
    top: 130%;
    right: 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(147, 147, 147, 0.15);

    .lan_item {
        &:first-child {
            border-radius: 8px 8px 0 0;
        }

        &:last-child {
            border-radius: 0 0 8px 8px;
        }

        background: #4999E8;
        backdrop-filter: blur(10px);
    }

    div {
        padding: 10px;
        min-width: 55px;
        text-align: center;
        &:first-child {
            border-radius: 8px 8px 0 0;
        }

        &:last-child {
            border-radius: 0 0 8px 8px;
        }
        &:hover {
            background: #C9DBFD;
        }
    }
}
@media (max-width: 1024px) {
    .languages {
        top: 50px;
        right: inherit;
    }
}