.burger_icon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #FFFFFF33;
    svg {
        position: relative;
        top: 0px;
    }
}

.side_menu {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #01315C;
    padding: 30px 20px;
}

.menu_icons {
    margin-top: 1px;
    display: flex;
    justify-content: space-between;
}


.cross_icon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #FFFFFF33;
    svg {
        position: relative;
        top: -1px;
    }
}

.links {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    margin-left: 30%;
    gap: 35px;
}

.link_title {
    cursor: pointer;
    display: flex;
    font-size: 18px;
    gap: 10px;
    color: #FFFFFF;
    svg {
        position: relative;
        top: 5px;
        height: 10px;
        width: 10px;
        transform: rotate(90deg);
    }
}

.nav_links {
    margin-top: 15px;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    a {
        color: #FFFFFF;
        font-size: 16px;
    }
}