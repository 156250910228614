.filterbox {
  user-select: none;
    position: absolute;
    z-index: 20;
    padding: 30px;
    width: 436px;
    top: calc(100% + 15px);
    border: 1px solid #F2F2F2;
    box-shadow: 0px 4px 8px 0px #4F4F4F26;
    background: #FFFFFF;
    border-radius: 20px;
}

@media (max-width: 1024px) {
  .filterbox  {
      width: 360px;
      left: -10px;
  }
}

.checkbox {
    padding: 12px 0;
    border-bottom: 1px solid #F2F2F2;
    display: flex;
    gap: 15px;
}

.label {
    font-size: 16px;
    color: #4F4F4F;
}

.filterbox .checkbox input[type="checkbox"] {
    display: none;
  }
  
  .filterbox .checkbox label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .filterbox .checkbox label::before {
    content: '';
    display: inline-block;
    width: 24px;
    flex-shrink: inherit;
    height: 24px;
    padding-right: 8px;
    background-repeat: no-repeat;
    background-image: url('./uncheked.svg');
  }
  
  .filterbox .checkbox input[type="checkbox"]:checked + label::before {
    background-image: url('./checked.svg');
  }