.high_title {
    background-image: url(../../assets/history-bg.png);
    padding: 150px 0 120px;
    text-align: center;
    font-weight: 700;
    font-size: 64px;
    color: #FFFFFF;
}

@media (max-width: 1024px) {
    .high_title {
        padding: 100px 0 60px;
        font-size: 32px;
    }
  }

.wrapper {
    padding: 0 240px 150px;
}

@media (max-width: 1900px) {
    .wrapper  {
      padding: 0 30px 100px;
    }
}

@media (max-width: 1440px) {
    .wrapper  {
      padding: 0 60px 100px;
    }
}
  
@media (max-width: 1024px) {
    .wrapper  {
      padding: 0 20px 20px;
    }
}

.input_container {
    position: relative;
    height: 55px;
    background-color: #FFFFFF;
    border-radius: 15px;
    border: 1px solid #E0E0E0;
    overflow: hidden;
    input {
        height: 100%;
        width: 100%;
        border: none;
        padding: 0 10px;
        font-size: 18px;
        &:focus-visible {
            outline: none;
        }
    }
    svg {
        position: absolute;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        fill: #C7C7CC;
        z-index: 100000000;
    }
    .cross {
        right: 40px;
    }
}

.category_buttons {
    margin: 30px 0;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    button {
        padding: 10px 25px;
        border-radius: 50px;
        font-size: 18px;
        color: #165089;
        background-color: #F3F3F7;
    }
    .active_button {
        color: #FFFFFF;
        background-color: #165089;
    }
}

.category_section {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.search_item {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.item_title {
    font-size: 20px;
    font-weight: 600;
    color: #000B16;
}

.item_text {
    color: #4F4F4F;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1;
    * {
        color: #4F4F4F !important; 
    }
}

.link {
    color: #165089;
}