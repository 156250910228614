.high_title {
    background-image: url(../../assets/history-bg.png);
    padding: 150px 0 120px;
    text-align: center;
    font-weight: 700;
    font-size: 64px;
    color: #FFFFFF;
}

@media (max-width: 1024px) {
    .high_title {
        padding: 100px 0 60px;
        font-size: 32px;
    }
}

.data_container {
    padding: 0 240px;
}

@media (max-width: 1440px) {
    .data_container {
        padding: 0 60px;
    }
}

@media (max-width: 1024px) {
    .data_container {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
    }
}

.data_body {
    margin-bottom: 30px;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    border-radius: 20px;
    background-color: #F3F3F7;
    img {
        width: 50%;
        object-fit: cover;
    }

    &:hover .nav_circle {        
        opacity: 1;
    }
    &:hover .title {
        color: #165089;
    }
}

@media (max-width: 1024px) {
    .data_body  {
        flex-direction: column;
        align-self: center;
        max-width: 600px;
        img {
            width: 100%;
            object-fit: cover;
        }
    }
    .data_body:nth-child(odd){
        flex-direction: column-reverse;
    } 
}

.data_text {
    flex: 0 0 50%;
    position: relative;
    cursor: pointer;
    width: 100%;
    padding: 60px; 
    color: #000B16;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    transition: .5s;
}

@media (max-width: 1024px) {
    .data_text  {
        gap: 10px;
        padding: 15px;
    }
}

.nav_circle {
    display: flex;
    position: absolute;
    bottom: 60px;
    right: 60px;
    height: 64px;
    width: 64px;
    border-radius: 50%;
    border: 2px solid #165089;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: .5s;
    svg {
        fill: #165089;
    }
}

@media (max-width: 1024px) {
    .nav_circle   {
        display: none;
    }
}

.title {
    position: relative;
    transition: .5s;
    font-weight: 700;
    font-size: 44px;
    z-index: 3;
}

@media (max-width: 1024px) {
    .title   {
        font-size: 22px;
    }
}

.background_title {
    position: absolute;
    font-weight: 700;
    width: calc(100% + 50px);
    font-size: 64px;
    z-index: -1;
    top: -0.65em;
    left: -0.4em;
    color: #E0E0E04D;
}

@media (max-width: 1024px) {
    .background_title    {
        display: none;
    }
}

.text {
    color: #4F4F4F;
    font-size: 18px;
}

@media (max-width: 1024px) {
    .text  {
        font-size: 16px;
    }
}
