.mapboxgl-ctrl-top-left {
  display: none;
}

.mapboxgl-ctrl-logo {
  position: relative;
  bottom: 20px;
}

.mapboxgl-ctrl-bottom-right {
  left: 0;
  right: auto;
}

.geolocation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #E05B5B;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #E05B5B;
    border-radius: 50%;
  }
}

.map_point {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;

  &:hover {
    background-color: red;
    color: #FFFFFF;
  }

  &:active {
    background-color: red;
    color: #FFFFFF;
  }
}

.geolocation_error {
  color: #E05B5B;
  text-align: center;
  font-size: 18px;
  padding-bottom: 5px;
}

.mapboxgl-canvas {
  width: 100% !important;
}

.card_item {
  display: flex;
  align-items: stretch;
  width: 340px;
  max-height: 100px;
  min-height: 100px;
  border-radius: 10px;
  overflow: hidden;
  z-index: 100;
  .card_img {
      object-fit: cover;
      width: 35%;
  }
}

.card_text {
  width: 65%;
  padding: 10px 20px;
  background-color: #F3F3F7;
  color: #000000;
  display: flex;
  align-items: center;
  font-size: 15px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-height: 1.5;

}