.navigation {
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 15px;
  line-height: 150%;
}

@media (max-width: 1024px) {
  .navigation {
      margin: 15px 0;
      font-size: 13px;
  }
}

.main_link {
  box-sizing: border-box;
  border-bottom: 1px solid transparent;
  color: #4F4F4F;
  cursor: pointer;

  &:hover {
    color: #165089;
    border-bottom: 1px solid #165089;
  }
  &:active {
    color: #000B16;
    border-bottom: 1px solid #000B16;
  }
}

.last_link {
  color: #9C9C9C;
}


