.main_cont {
    background-image: url(../../assets/main-bg.png);
    min-height: 100vh;
}

.title_container {
    padding-top: 270px;
    text-align: center;
    color: #FFFFFF
}
.first_title {
    font-weight: 700;
    font-size: 112px;

}

@media (max-width: 1024px) {
    .first_title {
        font-size: 38px;
        margin-bottom: 20px;
    }
}

.second_title {
    font-weight: 700;
    font-size: 72px;
}

@media (max-width: 1024px) {
    .second_title {
        font-size: 25px;
    }
}

.buttons_container {
    margin-top: 100px;
    display: flex;
    gap: 60px;
    justify-content: center;
}

.link_button {
    text-decoration: none;
    text-align: center;
    color: #FFFFFF;
    padding: 20px 40px;
    min-width: 250px;
    background-color: #3882CA;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
}

.data_body {
    display: flex;
    align-items: stretch;
    background-color: #01315C;
    img {
        max-width: 50%;
        object-fit: cover;
    }
}

.data_text {
    position: relative;
    cursor: pointer;
    width: 100%;
    padding: 100px; 
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    transition: .5s;

    &:hover .nav_circle {        
        opacity: 1;
    }
    &:hover .title {
        color: #4999E8;
    }
}

@media (max-width: 1920px) {
    .data_text{
        max-width: 50%;
        padding: 80px;
    }
}
@media (max-width: 1440px) {
    .data_text{
        padding: 30px;
    }
}
@media (max-width: 1024px) {
    .data_text{
        padding: 10px;
    }
}
.nav_circle {
    display: flex;
    position: absolute;
    bottom: 60px;
    right: 60px;
    height: 78px;
    width: 78px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: .5s;
}

@media (max-width: 1440px) {
    .nav_circle{
        height: 60px;
        width: 60px;
        bottom: 50px;
        right: 50px;
    }
}

@media (max-width: 1024px) {
    .nav_circle {
        display: none;
    }
}
.title {
    position: relative;
    transition: .5s;
    font-weight: 700;
    font-size: 64px;
    z-index: 3;
}

@media (max-width: 1440px) {
    .title {
        font-size: 44px;
    }
}

@media (max-width: 1024px) {
    .title {
        font-size: 22px;
    }
}

.background_title {
    position: absolute;
    font-weight: 700;
    font-size: 120px;
    z-index: -1;
    top: -0.65em;
    left: -0.4em;
    color: #16508926;
}

@media (max-width: 1920px) {
    .background_title{
        font-size: 100px;
    }
}
@media (max-width: 1440px) {
    .background_title{
        font-size: 64px;
        top: -1em;
    }
}

@media (max-width: 1024px) {
    .background_title{
        display: none;
    }
}

.text {
    font-size: 18px;
    color: #E0E0E0;
}

@media (max-width: 1024px) {
    .text{
        display: none;
    }
}


    
      
  

