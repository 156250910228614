.player_container {
    margin-top: 30px;
    width: 100%;
}

.player {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.svg_btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 50%;
    &:hover {
        background-color: #ECF2F4;
    }
}
.timer {
    flex: 0 0 45px;
}
.progress_container {
    flex: 1 1 auto;
}

.speed_body {
    position: relative;
}

.speed_container {
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    background: #FFFFFF;
    text-align: center;
    box-shadow: 0px 4px 8px 0px rgba(147, 147, 147, 0.15);
    div {
        padding: 10px 15px;
        border-bottom: 1px solid #ECF2F4;
    }
}
@media (max-width: 768px) {
    .speed_container {
        left: 100%;
        transform: translateX(-100%);
    }
}

.top_string {
    display: flex;
    align-items: center;
    gap: 10px;
}

.bottom_string {
    width: 100%;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    a {
        color: #111111;
    }
}

.btn_next {
    transform: rotate(180deg);
}