.calendar {
  width: 100%;
  max-width: 360px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  color: #FFFFFF;
}

@media (max-width: 1024px) {
  .calendar {
    box-shadow: none;
  }
}

.row {
  display: flex;
  background-color: #fff;
}

.header {
  display: flex;
  margin: 10px 0 30px;
  justify-content: space-between;
  align-items: center;
  background: #003c71;
  border-radius: 8px 8px 0 0;
  text-transform: capitalize;
  font-size: 22px;
  font-weight: 600;
  .col {
    padding: 0;
  }
}

@media (max-width: 1024px) {
  .header{
    background: #FFFFFF;
    color: #165089;
  }
}

.header .col-start,
.header .col-end {
  color: #4F4F4F;
  cursor: pointer;
}

.header .col-center {
  text-align: center;
}

.days {
  display: flex;
  justify-content: space-between;
  color: #4F4F4F;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
}

.col {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

.cell {
  color: #4F4F4F;
  border-radius: 4px;
  margin: 2px;
}

.cell.selected {
  border-radius: 50%;
  background: #005bb5;
  color: #FFFFFF;
}

.cell.disabled {
  color: #bbb;
}

.cell.today {
  color: #165089;
  font-weight: 900;
}

.body {
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}


.arrows {
  display: flex;
  gap: 50px;
}

@media (max-width: 1024px) {
  .arrows svg{
    fill: #165089;
  }
}
.icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right {
  transform: rotate(180deg);
}


