.high_title {
  background-image: url(../../assets/history-bg.png);
  padding: 150px 0 120px;
  text-align: center;
  font-weight: 700;
  font-size: 64px;
  color: #FFFFFF;
}

@media (max-width: 1024px) {
  .high_title {
      padding: 100px 0 60px;
      font-size: 32px;
  }
}

.wrapper {
  padding: 0 240px 150px;
}

@media (max-width: 1440px) {
  .wrapper  {
    padding: 0 60px 100px;
  }
}

@media (max-width: 1024px) {
  .wrapper  {
    padding: 0 20px 80px;
  }
}

.content {
  display: flex;
  gap: 60px;
  margin-top: 30px;
}

.news_container {
  flex: 0 1 70%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  svg {
    animation: rotate 1.3s linear infinite;
    width: 50px;
    height: 50px;
    stroke: #003c71;
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1024px) {
  .news_container  {
    flex: 1 1 70%;
  }
}

.test {
  display: flex;
  justify-content: center;
  align-items: center;
}
.date_container {
  display: flex;
  align-items: center;
  gap: 30px;
}

@media (max-width: 1024px) {
  .date_container{
      flex-direction: column;
      align-items: start;
      gap: 20px;
  }
}

.date {
  display: flex;
  align-items: center;
  gap: 15px;
}

.date span {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #F3F3F7;
  font-size: 25px;
  color: #4F4F4F;
}

@media (max-width: 1024px) {
  .date span{
    font-size: 18px;
  }
}

.cal_mobile_button {
  display: none;
}

@media (max-width: 1024px) {
  .cal_mobile_button{
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #165089;
    border-radius: 50%;
  }
}
.which_day {
  color: #000B16;
  font-size: 22px;
  font-weight: 600;
}

@media (max-width: 1024px) {
  .calendar{
      display: none;
  }
}

.cal_container {
  flex: 0 0 20%;
  padding: 20px;
  background-color: #003c71;
  border-radius: 20px;
}

.news {
  display: flex;
  align-items: stretch;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

.news_text {
  background-color: #F3F3F7;
  padding: 20px;
  border-radius: 0 10px 10px 0;
}

.title {
  font-size: 22px;
  font-weight: 600;
  color: #000B16;
}

.text {
  margin-top: 5px;
  color: #4F4F4F;
  font-size: 18px;
}

.calendar_mobile {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000B1666;
}

.cal_container_mobile {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFFFFF;
  padding: 25px;
  border-radius: 20px;
}

.cal_panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
  color: #000B16;
  svg {
    fill: #C7C7CC;
    font-size: 50px;
  }
}