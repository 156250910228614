.search_container {
    display: flex;
    align-items: center;
    position: relative;
    svg {
        cursor: pointer;
    }
}

@media (max-width: 1025px) {
    .search_container{
        position: static;
        // padding: 20px 10px;
        // left: 0;
        // top: 20px;
        // background-color: #F3F3F7;
        // width: 100%;
    }
}

.input_container {
    position: absolute;
    right: -10px;
    top: -55%;
    width: 0;
    opacity: 0;
    transition: all 0.3s ease;
    height: 40px;
    background-color: #FFFFFF;
    border-radius: 15px;
    overflow: hidden;
    input {
        display: none;
        &:focus-visible {
            outline: none;
        }
    }
}


.active {    
    width: 550px;
    z-index: 10000;
    opacity: 1;
    input {
        display: block;
        width: 100%;
        height: 100%;
        padding: 10px;
    }
    svg {
        position: absolute;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        fill: #C7C7CC;
        z-index: 100000000;
    }
    .cross {
        right: 40px;
    }
}
@media (max-width: 1025px) {
    .input_container{
        left: 0px;
        top: 20px;
        padding: 10px 95px 10px 20px;
        height: 55px;
        background-color: #dddddf;
        border-radius: 0;
    }
    .active {
        width: 100%;
        input {
            padding: 0 27px 0 40px;
            border-radius: 10px;
            border: none;
        }
        svg {
            left: 30px;
            top: 20px !important;
            transform: none;
        }
        .cross {
            top: 21px !important;
            left: unset;            
            right: 105px;
        }
    }
}

.cancel {
    display: none;
}

@media (max-width: 1025px) {
    .cancel {
        display: block;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        color: #165089;
    }
}

.dropdown {
    position: absolute;
    width: 550px;
    top: 35px;
    right: -10px;
    background-color: #FFFFFF;
    color: #4F4F4F;
    border-radius: 12px;
    div {
        padding: 10px 20px;
        border-bottom: 1px solid #E0E0E0;
    } 
    div:last-child {
        border: none;
    }
}

@media (max-width: 1025px) {
    .dropdown {
        width: 100%;
        right: 0;
        top: 75px;
        border-radius: 0;
    }
}

