.high_title {
    background-image: url(../../assets/history-bg.png);
    padding: 150px 0 120px;
    text-align: center;
    font-weight: 700;
    font-size: 64px;
    color: #FFFFFF;
}

@media (max-width: 1024px) {
    .high_title {
        padding: 100px 0 60px;
        font-size: 32px;
    }
  }

.wrapper {
    padding: 0 240px 150px;
}

@media (max-width: 1900px) {
    .wrapper  {
      padding: 0 30px 100px;
    }
}

@media (max-width: 1440px) {
    .wrapper  {
      padding: 0 60px 100px;
    }
}
  
@media (max-width: 1024px) {
    .wrapper  {
      padding: 0 20px;
    }
}

.setting_line {
    margin: 30px 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 1024px) {
    .setting_line  {
        margin: 20px 0;
        width: 100%;
        flex-direction: column;
    }
}

.filter_search_container {
    display: flex;
    gap: 20px;
}

.filter {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F3F3F7;
    border-radius: 50%;
}

.search {
    position: relative;
    svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        fill: #C7C7CC;
    }
    input {
        padding: 15px 40px 15px 20px;
        height: 50px;
        min-width: 360px;
        border: 1px solid #E0E0E0;
        border-radius: 20px;
        &:focus-visible {
            outline: none;
        }
    }
}

@media (max-width: 1024px) {
    .search  {
        width: 100%;
        input {
            min-width: 100%;
        }
        
    }
  }
.sectionButtons {
    position: relative;
    button {
    min-width: 150px;
    padding: 15px;
    font-weight: 600;
    border-radius: 20px;
    font-size: 17px;
    line-height: 20px;
    text-transform: capitalize;
    }
}
@media (max-width: 1024px) {
    .sectionButtons {
        align-self: center;
        button {
            margin-top: 20px;
        }
    }
}
.buttonActive {
    background: #165089;
    color: #FFFFFF;
    position: relative;
    z-index: 2;
}

.buttonPassive {
    background: #F3F3F7;
    color: #165089;
    z-index: 1;
    position: relative;
    right: 0;
}
.sectionButtons button:first-child {
    margin-right: -40px;
}

.organizations_container {
    margin-bottom: 150px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px 60px;
    justify-content: center;
}

@media (max-width: 1900px) {
    .organizations_container  {
      gap: 40px 30px;
    }
}

@media (max-width: 1024px) {
    .organizations_container {
      gap: 20px;
    }
}


